import React from 'react';
import classes from '../styles/quote.module.css';
import { NavLink } from 'react-router-dom';

export default function Quote() {
  return (
    <div className={`container ${classes.wrapper}`}>
      <span>Let's talk about your project.<br/>Contact us today!</span>
      <div className={`${classes.links}`}>
        <NavLink className={classes.btn} to={"/contact"}>Contact us</NavLink>
        <NavLink className={classes.btn} to={"tel:+19169559957"}>(916) 955-9957</NavLink>        
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from 'react-scroll';
import Navbar from "../components/navbar";
import background from "../assets/sidekix-media-WgkA3CSFrjc-unsplash.jpg";
import classes from "../styles/about.module.css";
import Quote from "../components/quote";
import Footer from "../components/footer";
import Loader from "../components/loader";

export default function About() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    scroll.scrollToTop({
      smooth: true,
      duration: 0
    });
  }, [])
  
  return (
    <div>
      <Navbar container={".about"} />
      <div className={"about"}></div>
      {!isImageLoaded && <div className={`container ${classes.placeholder}`}></div>}
      <div
        style={{ backgroundImage: `url(${background})`, display: isImageLoaded ? "flex" : "none" }}
        className={`container ${classes.header}`}        
      >
        <h1>About Us</h1>
      </div>
      <section className={`container ${classes.content}`}>
        <p>
          At United Homes, we do more than construct homes. We have a commitment
          to care, design excellence and bring your dream home to life. As a
          full service professional company, we specialize in land development,
          new home construction, remodeling work, and everything in between. By
          taking the time to understand your needs and personal preferences, we
          can ensure your custom home building experience with United Homes will
          be as fulfilling as it is engaging.
        </p>
        <p>
          Our success came from a hard work ethic and determination to produce
          quality in every aspect of the design build experience, from our
          clients experience to the finished product. Our construction and
          design processes are always evolving, incorporating the best in new
          technologies and techniques.
        </p>
        <p>
          We are committed to a seamless client experience. We create cohesive
          projects offering services including lot development, architectural
          design, construction documents, local construction, and finally the
          finishing selections of the interior design of your home. With your
          vision, we take care of all the details under one roof. Our track
          record is that of excellence, successful execution and repeat clients.
        </p>
      </section>
      <Quote />
      <Footer />
      <img
        src={background}
        onLoad={handleImageLoad}
        style={{ display: "none"}}      
      />
    </div>
  );
}

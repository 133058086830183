import React, { useEffect, useState, useRef } from "react";
import { Navbar as Comp, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import Logo from "../assets/logo.png";
import { NavLink } from "react-router-dom";
import "../styles/navbar.css";

export default function Navbar({ container }) {
  const navbarRef = useRef(null);
  const [isNavbarWhite, setIsNavbarWhite] = useState((!container || window.innerWidth <= 700) ? true : false);

  useEffect(() => {    
    const handleScroll = () => {
      if (!container || window.innerWidth <= 700) {
        return;
      }

      console.log("somethinf");

      const carousel = document.querySelector(container); // Adjust this selector based on your carousel component
      
      // console.log(carousel);

      if (navbarRef.current && carousel) {
        const navbarRect = navbarRef.current.getBoundingClientRect();
        const carouselRect = carousel.getBoundingClientRect();

        const intersects = navbarRect.bottom > carouselRect.top;

        setIsNavbarWhite(intersects);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const navbarClassName = `wrapper fixed-top ${isNavbarWhite ? "bg-color" : "bg-color fade-out"}`;


  return (
    <React.Fragment>
      <div style={{ marginBottom: 140 }} />
      <Comp ref={navbarRef} expand="lg" className={navbarClassName}>
        <Container className="container">
          <Comp.Brand href="#" className="pb-0">
            <img src={Logo} alt="Logo" className={"logo"} />
          </Comp.Brand>
          <Comp.Toggle aria-controls="navbarNav" className="toggle-btn" />
          <Comp.Collapse id="navbarNav" className={"navCollapse"}>
            <Nav className={`ms-auto ${"nav"}`}>
              <NavLink className={"link"} activeClassName={"active"} to="/">
                Home
              </NavLink>
              <NavLink
                className={"link"}
                activeClassName={"active"}
                to="/about"
              >
                About
              </NavLink>
              <NavLink
                className={"link"}
                activeClassName={"active"}
                to="/process"
              >
                Our Process
              </NavLink>
              <NavLink
                className={"link"}
                activeClassName={"active"}
                to="/portfolio"
              >
                Portfolio
              </NavLink>
              <NavLink
                className={"link"}
                activeClassName={"active"}
                to="/contact"
              >
                Contact Us
              </NavLink>
              {/* <NavLink
                className={'link'}
                activeClassName={'active'}
                to="https://www.instagram.com/allcityhomes/"
              >
                <FontAwesomeIcon icon={faInstagram} className={'icon'} />
              </NavLink> */}
            </Nav>
          </Comp.Collapse>
        </Container>
      </Comp>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import classes from "../styles/carousel.module.css";

import image1 from "../assets/m1.jpg";
import image2 from "../assets/m2.jpg";
import image3 from "../assets/m3.jpg";
import image4 from "../assets/m4.jpg";
import image5 from "../assets/m5.jpg";

import { NavLink } from "react-router-dom";
import Loader from "./loader";

const CustomArrow = ({ onClick, isPrev }) => {
  const arrowIcon = isPrev ? faChevronLeft : faChevronRight;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${classes.customArrow} ${
        isPrev ? classes.leftArrow : classes.rightArrow
      }`}
    >
      <FontAwesomeIcon color={"white"} icon={arrowIcon} />
    </button>
  );
};

const fadeAnimationHandler = (props, state) => {
  const transitionTime = props.transitionTime + "ms";
  const transitionTimingFunction = "ease-in-out";

  let slideStyle = {
    position: "absolute",
    display: "block",
    minHeight: "100%",
    zIndex: 0,
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction: transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction,
  };

  if (!state.swiping) {
    slideStyle = {
      ...slideStyle,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
  }

  return {
    slideStyle,
    selectedStyle: {
      ...slideStyle,
      opacity: 1,
      zIndex: 1,
      position: "relative",
      // backgroundColor: "green",
    },
    prevStyle: { ...slideStyle },
  };
};

export default function CustomCarousel() {
  const images = [image1, image2, image3, image4, image5];
  const totalSlides = images.length;
  const [selectedSlide, setSelectedSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSelectedSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [totalSlides]);

  const handleSlideChange = (index) => {
    setSelectedSlide(index);
  };


  return (
    // <div className={`container ${classes.carousel}`}>
      <div className={`container ${classes.carousel}`}>
        <Carousel
          selectedItem={selectedSlide}
          onChange={handleSlideChange}
          autoPlay
          interval={5000}
          transitionTime={1000}
          showThumbs={false}
          showStatus={false}
          animationHandler={fadeAnimationHandler}
          swipeable={false} // Disable swipe gestures
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && <CustomArrow onClick={onClickHandler} isPrev={true} />
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && <CustomArrow onClick={onClickHandler} isPrev={false} />
          }
        >
          {images.map((image, index) => (
            <div className={classes.slide} key={index}>
              <Loader src={image} />
              <div className={classes.textOverlay}>
                <h1>Your dream home awaits</h1>
                <p>
                  A custom home builder specializing in new construction homes,
                  distinctive
                  <br />
                  interiors, architecture, remodel, & more.
                </p>
                <div className={classes.links}>
                  <NavLink style={{ borderBottom: "none" }} to={"/process"}>
                    <button>Explore Our Process</button>
                  </NavLink>
                  <NavLink style={{ borderBottom: "none" }} to={"/contact"}>
                    <button>Contact Us</button>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    // </div>
  );
}

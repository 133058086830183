import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../components/navbar";
import classes from "../styles/contact.module.css";
import image from "../assets/deerbrooke/03.jpg";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import Footer from "../components/footer";

const schema = {
  name: Joi.string().required().label("Your Name"),
  email: Joi.string().required().email({ allowTld: true }).label("Email"),
  phone: Joi.number().required().label("Phone Number"),
  inquiry: Joi.string().required().label("Inquiry"),
};

export default function Contact() {  
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    inquiry: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    // let errors = Joi.validate(data, schema);          
    let result = validate();

    if (result){
      return null;
    }

    emailjs
      .sendForm(
        "service_7mwsmwz",
        "template_njfvhks",
        e.target,
        "kQOjo5QscBPuoAk38"
      )
      .then(() => {
        console.log("Email sent successfully");
        toast.success("Contact Sent!");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        toast.error("Failed to send contact!");
      });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const error = validateProperty(name, value);

    let errors = {
      ...formErrors,
      [name]: error,
    };

    let d = {
      ...data,
      [name]: value,
    };

    setFormErrors(errors);
    setData(d);
  };

  const validateProperty = (name, value) => {
    let obj = { [name]: value };
    let subSchema = { [name]: schema[name] };

    let { error } = Joi.validate(obj, subSchema);

    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const formErrors = {};
    const options = {
      abortEarly: false,
    };
    let { error: result } = Joi.validate(data, schema, options);

    console.log(result);
    if (result) {
      result.details.forEach((element) => {
        formErrors[element.context.key] = element.message;
      });
      setFormErrors(formErrors);
      return formErrors;
    }
    return null;
  };

  return (
    <div>
      <ToastContainer />
      <Navbar container={null} />
      <div className={"contact"}></div>
      <div className={`${classes.wrapper} container`}>
        <section className={`${classes.content}`}>
          <div className={classes.text}>
            <h1>Let's Build</h1>
            <h1>Together</h1>
          </div>
          <div className={classes.property}>
            <label>Call Us</label>
            <a href="tel:+19169559957">(916) 955-9957</a>
          </div>
          <div className={classes.property}>
            <label>Email Us</label>
            <a href="mailto:unitedhomesgc@gmail.com">unitedhomesgc@gmail.com</a>
          </div>
        </section>
        <section className={`${classes.content}`}>
          <h2>Contact Us</h2>
          <form id="application-form" onSubmit={onSubmit}>
            <div className="form-group mb-3">
              <input
                type="text"
                className={`form-control ${
                  formErrors.name ? "is-invalid" : ""
                }`}
                name="name"
                placeholder="Your Name"
                onChange={handleChange}
              />
              {formErrors.name && (
                <div className="invalid-feedback">
                  {formErrors.name}
                </div>
              )}
            </div>
            <div className="form-group mb-3">
              <input
                type="email"
                className={`form-control ${
                  formErrors.email ? "is-invalid" : ""
                }`}
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
              {formErrors.email && (
                <div className="invalid-feedback">
                  {formErrors.email}
                </div>
              )}
            </div>
            <div className="form-group mb-3">
              <input
                type="text"
                className={`form-control ${
                  formErrors.phone ? "is-invalid" : ""
                }`}
                name="phone"
                placeholder="Phone Number"
                onChange={handleChange}
              />
              {formErrors.phone && (
                <div className="invalid-feedback">
                  {formErrors.phone}
                </div>
              )}
            </div>
            <div className="form-group mb-3">
              <input
                className={`form-control ${
                  formErrors.inquiry ? "is-invalid" : ""
                }`}
                name="inquiry"
                placeholder="Tell us about your inquiry"
                onChange={handleChange}
              />
              {formErrors.inquiry && (
                <div className="invalid-feedback">
                  {formErrors.inquiry}
                </div>
              )}
            </div>            
            <button
              type="submit"
              className="btn btn-primary"
              // disabled={validate() || formSubmitted}
            >
              Send
            </button>
          </form>
        </section>
      </div>
      <Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import Navbar from "../components/navbar";
import Carousel from "../components/carousel";
import Loader from "../components/loader";
import classes from "../styles/home.module.css";
import p1 from "../assets/home/p1.jpg";
import p2 from "../assets/home/p2.jpg";
import p3 from "../assets/home/p3.jpg";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";

export default function Home() {  
  const projects = [
    { image: p1, content: "Deerbrooke Trail", id: "deerbrooke" },
    { image: p2, content: "Grand Knoll", id: "grandknoll" },
    { image: p3, content: "Newman", id: "newman" },
  ];

  useEffect(() => {
    scroll.scrollToTop({
      smooth: true,
      duration: 0,
    });
  }, []);

  return (
    <div>
      <Navbar container={".carousel"} />
      <Carousel />
      <section className={classes.projects}>
        <h1>Our Projects</h1>

        <div className={`row container ${classes.projectWrapper}`}>
          {projects.map((project, index) => (
            <div
              key={index}
              className={`col-12 col-md-6 col-lg-4 ${classes.card}`}
            >
              <NavLink to={`/portfolio/${project.id}`}>
                <div
                  className={`card ${classes.cardInner}`}
                  style={{ backgroundImage: `url(${project.image})` }}
                >
                  <div className={`${classes.cardOverlay}`}>
                    <span>{project.content}</span>
                  </div>
                </div>
              </NavLink>
            </div>
          ))}
        </div>

        <NavLink to={"/portfolio"} className={classes.link}>
          See more projects
        </NavLink>
      </section>
      <Footer />
      {/* <img
        src={p1}
        onLoad={(handleImageLoad)}
        style={{ display: "none" }}
      />
      <img
        src={p2}
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />
      <img
        src={p3}
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      /> */}
    </div>
  );
}

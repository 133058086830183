import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { RingLoader } from "react-spinners";
import Navbar from "../components/navbar";
import Quote from "../components/quote";
import Footer from "../components/footer";

import background from "../assets/processpage/Main.jpg";
import step1 from "../assets/processpage/01.jpg";
import step2 from "../assets/processpage/02.jpg";
import step3 from "../assets/processpage/03.jpg";
import step4 from "../assets/processpage/04.jpg";
import step5 from "../assets/processpage/05.jpg";

import classes from "../styles/process.module.css";
import Loader from "../components/loader";

export default function Process() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isSectionRendered, setIsSectionRendered] = useState(false);
  const sectionRef = useRef(null);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    scroll.scrollToTop({
      smooth: false,
      duration: 0
    });
  
    const sectionElement = sectionRef.current;
  
    if (sectionElement) {
      const observer = new IntersectionObserver((entries) => {
        const areAllVisible = entries.every((entry) => entry.isIntersecting);
  
        if (areAllVisible) {
          // Set a timeout of 2 seconds before setting isSectionRendered to true
          setTimeout(() => {
            setIsSectionRendered(true);
          }, 1000);
          
          observer.disconnect();
        }
      });
  
      const elementsToObserve = sectionElement.querySelectorAll("*");
  
      elementsToObserve.forEach((element) => {
        observer.observe(element);
      });
  
      return () => {
        observer.disconnect();
      };
    }
  }, []);
  

  return (
    <div>
      <Navbar container={".process"} />
      <div className={"process"}></div>
      {!isImageLoaded && (
        <div className={`container ${classes.placeholder}`}></div>
      )}

      <div
        style={{
          backgroundImage: `url(${background})`,
          display: isImageLoaded ? "flex" : "none",
        }}
        className={`container ${classes.header}`}
      >
        <h1>The United Homes Process</h1>
      </div>

      <section className={`container ${classes.content}`}>
        <p>
          At United Homes, we understand that every building project begins with
          a vision and a budget. As a full-service construction company, we are
          equipped to take on any construction or remodeling project, regardless
          of its size. We have the expertise and knowledge necessary to deliver
          your dream home, paying special attention to detail every step of the
          way. Our objective is to cultivate and maintain a team of highly
          skilled construction professionals who serve our customers with
          honesty and integrity, always putting their needs first and providing
          them with a hassle-free experience.
        </p>
        <p>
          We take pride in what we do, and that's why we excel at it. We
          encourage you to explore our process to discover what we can do for
          you. We are excited about the opportunity to serve you and bring your
          vision to life.
        </p>
      </section>

      <section
        ref={sectionRef}        
        className={`container ${classes.main}`}
      >
        <h1>Our Step by Step Process</h1>

        {!isSectionRendered && (
          <RingLoader color="black" size={250} className={classes.ring} />
        )}

        <div className={`${classes.steps} ${isSectionRendered ? classes.rendered : classes.loading}`}>
          <div className={`container ${classes.step}`}>
            <Loader src={step1} />
            <div className={`${classes.stepContent}`} style={{ top: 0 }}>
              <h1>01</h1>
              <h2>
                We help our clients find land, draw and <br />
                design plans.
              </h2>
            </div>
          </div>

          <div className={`container ${classes.step} ${classes.special}`}>
            <Loader src={step2} />

            <div className={`${classes.stepContent}`}>
              <h1>02</h1>
              <h2>
                After plans are finalized, we will create <br />a cost breakdown
                for your project.
              </h2>
            </div>

            <div className={`${classes.stepContent}`}>
              <h1>03</h1>
              <h2>
                Once cost estimate is approved and <br />
                financing is secured, we will apply and <br />
                draw the required permits to build.
              </h2>
            </div>
          </div>

          <div className={`container ${classes.step}`}>
            <Loader src={step3} />
            <div className={`${classes.stepContent}`} style={{ bottom: 0 }}>
              <h1>04</h1>
              <h2>
                We will break ground and begin the <br />
                construction on your new home.
              </h2>
            </div>
          </div>

          <div className={`container ${classes.step}`}>
            <Loader src={step4} />
            <div className={`${classes.stepContent}`} style={{ bottom: 50 }}>
              <h1>05</h1>
              <h2>
                After inspections, the final walk-thru, <br />
                and closing, you will receive the keys <br />
                to your home.
              </h2>
            </div>
          </div>

          <div className={`container ${classes.step}`}>
            <Loader src={step5} />
            <div className={`${classes.stepContent}`} style={{ bottom: 50 }}>
              <h1>06</h1>
              <h2>
                We will provide you with a new home <br />
                warranty.
              </h2>
            </div>
          </div>
        </div>
      </section>
      <Quote />
      <Footer />
      <img
        src={background}
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />
    </div>
  );
}

import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { RingLoader } from "react-spinners";
import Navbar from "../components/navbar";
import classes from "../styles/portfolio.module.css";

import crestmoor from "../assets/projects/Crestmoor.jpg";
import deerbrooke from "../assets/projects/Deerbrooke.jpg";
import dogwood from "../assets/projects/Dogwood.jpg";
import ellis from "../assets/projects/Ellis.jpg";
import gatelane from "../assets/projects/Gatelane.jpg";
import grandknoll from "../assets/projects/Grandknoll.jpg";
import newman from "../assets/projects/Newman.jpg";
import oliver from "../assets/projects/Oliver.jpg";
import pacific from "../assets/projects/Pacific.jpg";
import paradise from "../assets/projects/Paradise.jpg";
import pinecentral from "../assets/projects/Pinecentral.jpg";
import redbud from "../assets/projects/Redbud.jpg";

import image from "../assets/carousel2.jpg";
import Quote from "../components/quote";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import Loader from "../components/loader";

const Portfolio = () => {
  let projects = [
    { name: "Deerbrooke Trail", id: "deerbrooke", image: deerbrooke },
    { name: "Grand Knoll", id: "grandknoll", image: grandknoll },
    { name: "Newman", id: "newman", image: newman },
    { name: "Crestmoor", id: "crestmoor", image: crestmoor },
    { name: "Pacific", id: "pacific", image: pacific },
    { name: "Paradise", id: "paradise", image: paradise },
    { name: "Redbud", id: "redbud", image: redbud },
    { name: "Gate Lane", id: "gatelane", image: gatelane },
    { name: "Dogwood", id: "dogwood", image: dogwood },
    { name: "Ellis", id: "ellis", image: ellis },
    { name: "Oliver", id: "oliver", image: oliver },
    { name: "Pine Central", id: "pinecentral", image: pinecentral },
  ];

  const [isSectionRendered, setIsSectionRendered] = useState(false);
  const sectionRef = useRef(null);

  useLayoutEffect(() => {
    scroll.scrollToTop({
      smooth: false,
      duration: 0,
    });
  })

  useEffect(() => {    
    const sectionElement = sectionRef.current;

    if (sectionElement) {
      const observer = new IntersectionObserver((entries) => {
        const areAllVisible = entries.every((entry) => entry.isIntersecting);

        if (areAllVisible) {
          setIsSectionRendered(true);
          observer.disconnect();
        }
      });

      const elementsToObserve = sectionElement.querySelectorAll("*");

      elementsToObserve.forEach((element) => {
        observer.observe(element);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);
  
  useEffect(() => {
    const middlePosition = window.innerHeight / 2;
    setTimeout(() => {
      window.scrollTo(0, middlePosition);
    }, 500);
  }, []);

  return (
    <div>
      <Navbar container={".portfolio"} />
      <div className={"portfolio"}></div>
      {!isSectionRendered && (
        <div className={classes['centered-ring']}>
          <RingLoader color="black" size={250} style={{
            zIndex: 200,   
          }} />
        </div>
      )}

      <div className={`${
          isSectionRendered ? classes.rendered : classes.loading
        }`}> 
        <div
          ref={sectionRef}
          className={`${classes.container} container`}
        >
          {projects.map((project, index) => (
            <NavLink
              style={{ border: "none", borderWidth: 0 }}
              to={`/portfolio/${project.id}`}
            >
              <div className={`${classes.card} card`} key={index}>
                <Loader src={project.image} />
                <div className={classes.cardOverlay}>
                  <h2>{project.name}</h2>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
        <Quote />
        <Footer />
      </div>

    </div>
  );
};

export default Portfolio;

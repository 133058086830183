import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import classes from "../styles/project.module.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Loader from "../components/loader";

const CustomArrow = ({ onClick, isPrev }) => {
  const arrowIcon = isPrev ? faChevronLeft : faChevronRight;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${classes.customArrow} ${
        isPrev ? classes.leftArrow : classes.rightArrow
      }`}
    >
      <FontAwesomeIcon color={"white"} icon={arrowIcon} />
    </button>
  );
};

export default function Project() {
  const { projectId } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    setSelectedItem(0);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const format = (num) => {
    if (num < 10) {
      return "0".concat(num);
    } else {
      return num;
    }
  };

  const handleImageClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  const images = Array.from({ length: 10 }, (_, index) => {
    return `/assets/${projectId}/${format(index + 1)}.jpg`;
  });

  return (
    <div style={{ marginTop: 140 }}>
      <Navbar />
      <div className={isFullScreen ? classes.overlay : ""}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && <CustomArrow onClick={onClickHandler} isPrev={true} />
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && <CustomArrow onClick={onClickHandler} isPrev={false} />
          }
          className={`container ${classes.carousel}`}
          showArrows={!isMobile}
          centerSlidePercentage={isMobile ? 90 : 60}
          centerMode={true}
          swipeable={isMobile}
          showIndicators={!isMobile}
          autoPlay={!isFullScreen ? true : false}
          stopOnHover={true}
          transitionTime={500}
          interval={5000}
          selectedItem={selectedItem}
          onChange={(index) => setSelectedItem(index)}
        >
          {images.map((path, index) => (
            <div
              key={index}
              className={`${classes.slide} ${
                selectedItem === index ? classes.centerImage : ""
              }`}
              onClick={handleImageClick}
            >
              <Loader
                src={path}
                styles={{
                  objectFit: "cover",
                  height: window.innerWidth <= 992 ? "50vh" : "100%",
                }}
                key={index}
              />
              {/* <img src={path} alt="" className={classes.image} key={index} />; */}
            </div>
          ))}
        </Carousel>
      </div>
      {isFullScreen && (
        <div
          className={classes.fullScreen}
          onClick={() => setIsFullScreen(!isFullScreen)}
        >
          <img
            src={images[selectedItem]}
            alt=""
            className={classes.fullScreenImage}
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
